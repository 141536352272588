<template>
    <!-- Include the navigation component at the top -->
    <navigation></navigation>
  
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
  <router-link v-for="(post) in paginatedPosts" :key="post.id" :to="'/blogitem/' + post.id" class="text-decoration-none">
    <div class="p-4 blogitem">
      <!-- Style for the rest of the items -->
      <div v-if="!loading">
      <img :src="post.imageUrl" alt="Blog Post Image" class="w-full h-auto loading-overlay rounded-3xl">
      <h2 class="text-xl font-bold mb-2 blogitemtitle">{{ post.title.rendered }}</h2>
      <p class="text-gray-700" v-html="post.excerpt.rendered"></p>
      </div>
      <div v-if="loading" class="loading-message">
        <center><img alt="Loading" src="/assets/images/loading.gif"></center>
      </div>
    </div>
  </router-link>
    </div>
  
    <!-- Pagination controls -->
    <div class="mt-4 pagination text-center">
      <button @click="changePage(-1)" :disabled="currentPage === 1">Vorige</button>
      <span class="mx-2">Page {{ currentPage }} of {{ totalPages }}</span>
      <button @click="changePage(1)" :disabled="currentPage === totalPages">Volgende</button>
    </div>
  </div>
  
  
  <!-- Foooter -->
  <section class="bg-black">
  <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
      <p class="mt-8 text-base leading-6 text-center text-gray-400">
          © 2023 Crowdnet, Inc. All rights reserved.
      </p>
  </div>
  </section>
  </template>
  
  <script>
  import axios from 'axios';
  import Navigation from './NavigationTop.vue';
  
  export default {
  name: 'WebdesignPage',
  data() {
    return {
      posts: [],
      itemsPerPage: 6,
      currentPage: 1,
      loading: true, // Initially set to true to show the loading message
      dataLoaded: false, // Flag to indicate if the data has been loaded
    };
  },
  components: {
    // Register the navigation component
    Navigation,
  },
  computed: {
    paginatedPosts() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.posts.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.posts.length / this.itemsPerPage);
    },
  },
  mounted() {
    this.fetchData();
    setTimeout(() => {
      // Set loading to false when the operation is complete
      this.loading = false;
      this.dataLoaded = true;
    }, 2500); // Replace 2000 with the actual time your operation takes
  },
  methods: {
    fetchData() {
      axios.get('https://crowdnet.be/beheer/wp-json/wp/v2/posts?categories=6')
        .then(response => {
          this.posts = response.data;
          this.fetchImages(); // Fetch images after fetching posts
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    },
    fetchImages() {
      // Fetch images based on post data
      this.posts.forEach(post => {
        const imageId = post.featured_media;
        axios.get(`https://crowdnet.be/beheer/wp-json/wp/v2/media/${imageId}`)
          .then(response => {
            // Assuming the structure is similar to what you provided
            const imageUrl = response.data.guid.rendered;
            // Add imageUrl to each post
            post.imageUrl = imageUrl;
          })
          .catch(error => {
            console.error('Error fetching image:', error);
          });
      });
    },
    changePage(offset) {
      this.currentPage += offset;
    },
  },
  };
  </script>
  
  <style>
  .header {
  height: 500px;
  background: url('https://crowdnet.be/beheer/wp-content/uploads/2023/12/crowdnet-header-scaled.webp') no-repeat top center fixed;
  background-size: cover;
  align-items: center;
  color: white;
  /* Additional background properties can be added based on your design requirements */
  
  }
  .header h1 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -.1rem;
  margin-bottom: 1rem;
  }
  .header h2 {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -.1rem;
  }
  .loading-message {
  padding: 20px;
  text-align: center;
  }
  </style>
  