<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  title: 'Crowdnet',
};
</script>

<style>
/* Add your global styles here */
</style>
