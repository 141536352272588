// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Home from '../components/Home.vue';
import Blog from '../components/Blog.vue';
import Nieuws from '../components/Nieuws.vue';
import Blogitem from '../components/Blogitem.vue';
import Example from '../components/Example.vue';
import Tech from '../components/Tech.vue';
import Webdesign from '../components/Webdesign.vue';
import Webdevelopment from '../components/Webdevelopment.vue';
import Login from '../components/admin/Login.vue';
import Dashboard from '../components/admin/Dashboard.vue';
import Logout from '../components/admin/Logout.vue';
import '../assets/styles/tailwind.css';
import '../assets/styles/style.css';

const routes = [
  {
    path: '/about',
    name: 'HomePage',
    component: Home,
  },
  {
    path: '/',
    name: 'BlogPage',
    component: Blog,
  },
  {
    path: '/Nieuws',
    name: 'NieuwsPage',
    component: Nieuws,
  },
  {
    path: '/Tech',
    name: 'TechPage',
    component: Tech,
  },
  {
    path: '/Webdesign',
    name: 'WebdesignPage',
    component: Webdesign,
  },
  {
    path: '/Webdevelopment',
    name: 'WebdevelopmentPage',
    component: Webdevelopment,
  },
  {
    path: '/blogitem/:id',
    name: 'BlogItem',
    component: Blogitem,
  },
  {
    path: '/Example',
    name: 'ExamplePage',
    component: Example,
  },
  // Admin routes
  {
    path: '/cmsadmin',
    name: 'AdminLogin',
    component: Login,
  },
  {
    path: '/cmsadmin/dashboard',
    name: 'AdminDashboard',
    component: Dashboard,
  },
  {
    path: '/cmsadmin/logout',
    name: 'AdminLogout',
    component: Logout,
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;