<template>
  <div class="grid grid-cols-1">
    <!-- Include the navigation component at the top -->
    <navigation></navigation>
    <br><div class="menubg"><h1>Title</h1></div>
    <!-- TW Elements is free under AGPL, with commercial license required for specific uses. See more details: https://tw-elements.com/license/ and contact us for queries at tailwind@mdbootstrap.com --> 

    <div v-if="post" class="col-span-2 bg-white p-4 rounded shadow">
      <h2 class="text-xl font-bold mb-2">{{ post.title.rendered }}</h2>
      <p class="text-gray-700" v-html="post.content.rendered"></p>
    </div>
    <div v-else class="col-span-3 md:col-span-1">
      <div v-if="loading" class="bg-white p-4 rounded shadow">
        <p class="text-gray-700">Loading...</p>
      </div>
      <div v-else class="bg-white p-4 rounded shadow">
        <p class="text-gray-700">No data available</p>
      </div>
    </div>
  </div>

  <!-- Foooter -->
  <section class="bg-black">
    <div class="max-w-screen-xl px-4 py-12 mx-auto space-y-8 overflow-hidden sm:px-6 lg:px-8">
        <p class="mt-8 text-base leading-6 text-center text-gray-400">
            © 2023 Crowdnet, Inc. All rights reserved.
        </p>
    </div>
  </section>
</template>

<script>
// Import axios
import axios from 'axios';
import Navigation from './NavigationTop.vue';

export default {
  computed: {
    formattedContent() {
      return this.sanitizeHTML(this.post.title.rendered);
    },
  },
  components: {
    // Register the navigation component
    Navigation,
  },
  // Important: use the name 'BlogPage' here
  name: 'BlogItem',
  data() {
    return {
      post: null,
      loading: true, // Added loading variable
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      // Access the UID from the route parameters
      const id = this.$route.params.id;
      console.log(id); // Check if UID is correct

      // Set loading to true before making the request
      this.loading = true;

      // Make an Axios GET request
      axios.get('https://crowdnet.be/beheer/wp-json/wp/v2/posts/' + id + '?_embed')
        .then(response => {
          // Access the post directly, no need for find
          this.post = response.data;

          // Check if the post ID matches the expected ID
          if (this.post.id !== id) {
            console.error('Post ID does not match the expected ID');
          }
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          // Set loading to false regardless of success or failure
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
/* Your component styles go here */ 
.menubg {
  background: url('https://images.unsplash.com/photo-1517055729445-fa7d27394b48?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D') no-repeat center center fixed;
  background-size: cover;
  align-items: center;
  justify-content: center;  padding-bottom: 150px;
  margin-top: -100px;
}
.menubg h1 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: -.1rem;
  margin-bottom: 1rem;
  text-align: center;
  margin-top: 200px;
}
</style>